<template>
  <Auth>
    <section class="card-auth">
      <div class="logo-sesi">
        <ApplicationLogo />
      </div>

      <h2
          class="auth-title"
      >
        Redefina sua nova senha
      </h2>

      <b-card-text>
        <feather-icon class="icons-color" icon="CheckCircleIcon" />
        Sua senha deve conter entre 8 e 16 caracteres.
      </b-card-text>

      <b-card-text>
        <feather-icon class="icons-color" icon="CheckCircleIcon" />
        Utilize ao menos duas das seguintes opções:

        <ul>
          <li>Letra Maiúscula</li>
          <li>Letra Minúscula</li>
          <li>Número</li>
          <li>Caractere Especial</li>
        </ul>
      </b-card-text>

      <b-card-text>
        <feather-icon class="icons-color" icon="SlashIcon" />
        Não utilize caracteres com acento.
      </b-card-text>

      <b-alert
          v-if="alert.show"
          show
          fade
          :variant="alert.variant"
      >
        <div class="custom-alert">
              <span>
                <BIconCheckCircle v-if="alert.variant === 'success'" />
                <BIconExclamationCircle v-if="alert.variant === 'danger'" />
                {{ alert.message }}
              </span>
        </div>
      </b-alert>

      <!-- form -->
      <b-form
          class="auth-login-form"
          @submit.prevent="resetPassword"
      >
        <!-- password -->
        <b-form-group
            label="Senha *"
            label-for="password"
        >
          <b-form-input
              id="password"
              name="password"
              type="password"
              v-model="newPasswordData.password"
              @focus="alert.show = false"
              :class="formErrors.password ? 'border-danger' : ''"
              placeholder="********"
              autocomplete="off"
          />
          <small class="text-danger">{{ formErrors.password }}</small>
        </b-form-group>

        <!-- password confirmation -->
        <b-form-group
            label="Confirme a senha *"
            label-for="passwordConfirmation"
        >
          <b-form-input
              id="passwordConfirmation"
              name="passwordConfirmation"
              type="password"
              v-model="newPasswordData.passwordConfirmation"
              @focus="alert.show = false"
              :class="formErrors.passwordConfirmation ? 'border-danger' : ''"
              placeholder="********"
              autocomplete="off"
          />
          <small class="text-danger">{{ formErrors.passwordConfirmation }}</small>
        </b-form-group>

        <!-- submit buttons -->
        <button :disabled="disabledButton" type="submit" class="btn button-form btn-block">
          {{ msgButton }}
        </button>
      </b-form>

      <p class="text-center mt-1">
        <b-link :to="{ name:'auth-login' }">
          <BIconChevronLeft />
          <span class="to-login">&nbsp;Volte ao Login</span>
        </b-link>
      </p>

    </section>
  </Auth>
</template>

<script>
import Auth from "@/views/pages/authentication/BaseAuth/Auth";
import {
  BIconGoogle,
  BIconExclamationCircle,
  BIconChevronLeft,
  BIconCheckCircle,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BForm,
  BButton,
  BAlert,
} from 'bootstrap-vue';
import { CheckCircleIcon, SlashIcon } from 'vue-feather-icons'
import ApplicationLogo from "@/views/components/custom/logo-sesi/ApplicationLogo";
import { validation } from "@core/mixins/validation/validation";
import { messages } from "@core/mixins/validation/messages";
import { modalGenericModel } from "@/libs/sweetalerts";

export default {
  components: {
    Auth,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BForm,
    BAlert,
    BButton,
    BIconGoogle,
    BIconExclamationCircle,
    BIconChevronLeft,
    BIconCheckCircle,
    ApplicationLogo,
    CheckCircleIcon,
    SlashIcon,
  },
  mixins: [validation, messages],

  props: ['codigo'],

  data() {
    return {
      newPasswordData: {
        code: this.codigo,
        password: '',
        passwordConfirmation: '',
      },

      alert: {
        show: false,
        message: '',
        variant: ''
      },

      loadingShow: true,

      disabledButton: false,
      msgButton: 'Salvar',

      formErrors: {},
    }
  },

  methods: {
    async resetPassword() {
      if(this.validation()) {
        this.buttonLoading(true);

        await this.$http.post(this.$api.RedefinirSenha, this.newPasswordData)
            .then((response) => {
              const status = response.request.status;

              if(status === 200) {
                this.clear();

                modalGenericModel(
                  'Senha alterada com sucesso.',
                  'Volte à página de login e tente acessar novamente com sua nova senha',
                  require('@/assets/custom-icons/cora-icons/success.png'),
                  'Ok'
                ).then((result) => {
                  if (result.isConfirmed) {
                      this.$router.push({ name:'auth-login' });
                  }
                });
              }
            })
            .catch((error) => {
              this.errorHandling(error.response);
            })

        this.buttonLoading(false);
      }
    },

    errorHandling(response) {
      const status = response.status;
      const message = response.data.error || 'Não foi possível realizar sua solicitação.';

      if(status === 404) {
        this.showAlert(
            message,
            'danger'
        );
      } else {
        this.showAlert(
            this.impossibleMsg,
            'danger'
        );
      }
    },

    validation() {
      if(!this.newPasswordData.code) {
        this.showAlert(
            this.invalidTokenForgotPasswordMsg,
            'danger'
        );

        return false;
      }

      this.passwordValidate(this.newPasswordData.password);

      this.passwordConfirmation(this.newPasswordData.password, this.newPasswordData.passwordConfirmation);

      // Verifica se o formulário está preenchido ou não
      if(this.formValidationRun()) {
        return true;
      } else {
        this.formErrors = {};
        this.formErrors = this.formValidationErrors;

        return false;
      }
    },

    showAlert(message, variant) {
      this.alert.show = true;
      this.alert.message = message;
      this.alert.variant = variant;
    },

    buttonLoading(disabled) {
      if(disabled) {
        this.disabledButton = true;
        this.msgButton = 'Processando...';
      } else {
        this.disabledButton = false;
        this.msgButton = 'Entrar';
      }
    },

    clear() {
      localStorage.removeItem('emailForgotPassword');

      this.newPasswordData = {
        code: '',
        password: '',
        passwordConfirmation: ''
      };

      this.formErrors = {};
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
